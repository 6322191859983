<template>
    <div>
        <div class="wrapper clearfix" id="wrapperParallax">
            <Navbar />
            <div id="home">

                <section class="slider slider-1" id="slider-1">
                    <div class="container-fluid pe-0 ps-0">
                        <div class="slider-carousel owl-carousel carousel-navs carousel-dots" data-slide="1"
                            data-slide-rs="1" data-autoplay="true" data-nav="true" data-dots="true" data-space="0"
                            data-loop="true" data-speed="150">

                            <div class="slide bg-overlay bg-overlay-dark-slider">
                                <div class="bg-section"><img :src="require('../../assets/images/sliders/1.jpg')"
                                        alt="Background" />
                                </div>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <div class="slide-content">
                                                <h1 class="slide-headline">Neden Eksper Enerji?</h1>
                                                <div class="slide-action">
                                                    <div class="slide-list">
                                                        <div class="icon"> <i class="flaticon-040-green-energy"></i></div>
                                                        <div class="icon"> <i class="flaticon-020-factory"></i></div>
                                                        <div class="icon"> <i class="flaticon-022-sun-energy"></i></div>
                                                    </div><a class="btn btn--primary"
                                                        href="./Hizmetler/ElektrikliAracSarjIstasyonu">
                                                        <span>Hizmetlerimiz</span><i class="energia-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-4 offset-lg-2 d-flex">
                                            <div class="slider-panel-holder">
                                                <div class="slider-panel"><i class="panel-icon flaticon-019-electric-tower"></i>
                                                    <h5 class="panel-title">Neden eksper enerji</h5>
                                                    <p class="panel-desc">
                                                    <div class="advantages-list-holder">
                                                        <ul class="list-unstyled advantages-list">
                                                            <li>Uzman Kadro ile Hizmet</li>
                                                            <li>Anahtar Teslim Çözümler</li>
                                                            <li>Yenilikçi Vizyon</li>
                                                            <li>Çevre Dostu Misyon</li>
                                                            <li>Sorunsuz Enerji Temini</li>
                                                        </ul>
                                                    </div>
                                                    </p>
                                                    <!-- <a href="page-about.html"><i class="energia-arrow-right"> </i></a> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="slide bg-overlay bg-overlay-dark-slider">
                                <div class="bg-section"><img
                                        :src="require('../../assets/gt/9-Solar-Panel-Ideas-for-home.jpg')"
                                        alt="Background" />
                                </div>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <div class="slide-content">
                                                <h1 class="slide-headline">Güneşin Enerjisi Size Yeter!</h1>
                                                <!-- <p class="slide-desc">hemen iletişime geçin</p> -->
                                                <div class="slide-action">
                                                    <div class="slide-list">
                                                        <div class="icon"> <i class="flaticon-038-ecology"></i></div>
                                                        <div class="icon"> <i class="flaticon-039-wind-mill"></i></div>
                                                        <div class="icon"> <i class="flaticon-004-solar-panel"></i></div>
                                                    </div><a class="btn btn--primary" href="./Blog">
                                                        <span>Blog</span><i class="energia-arrow-right"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-4 offset-lg-2 d-flex">
                                            <div class="slider-panel-holder">
                                                <div class="slider-panel"><i class="panel-icon flaticon-019-electric-tower"></i>
                                                    <h5 class="panel-title">Yakınınızdayız</h5>
                                                    <p class="panel-desc">
                                                    <div class="advantages-list-holder">
                                                        <ul class="list-unstyled advantages-list">
                                                            <li>Elektrik faturanızdan tasarruf edersiniz.</li>
                                                            <li>Zamlardan etkilenmezsiniz.</li>
                                                            <li>Ürettiğiniz enerjinin fazlasını satabilirsiniz.</li>
                                                            <li>Çevre dostu, yeşil bir teknoloji kullanırsınız.</li>
                                                            <li>Bakım maliyetleri yoktur.</li>
                                                        </ul>
                                                    </div>
                                                    </p>
                                                    <!-- <a href="page-about.html"><i class="energia-arrow-right"> </i></a> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="about about-1" id="about-1">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-lg-5">
                                <div class="about-img">
                                    <div class="about-img-holder bg-overlay">
                                        <div class="bg-section"><img :src="require('../../assets/gt/teklif_al.jpg')"
                                                alt="about Image" /></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-7 ">
                                <div class="heading heading-1">
                                    <p class="heading-subtitle heading-subtitle-bg">Enerji faturalarınızın kontrolünü
                                        elinize alın</p>
                                    <h2 class="heading-title">Ücretsiz kişiselleştirilmiş fiyat teklifi </h2>
                                </div>
                                <div class="about-block card  shadow p-3 mb-5 bg-white rounded">
                                    <div class="container mt-4">
                                        <div class="row">
                                            <div class="col-6">
                                                <input type="text" class="form-control" placeholder="Ad" v-model="Ad"
                                                    maxlength="100">
                                            </div>
                                            <div class="col-6">
                                                <input type="text" class="form-control" placeholder="Soyad" v-model="Soyad"
                                                    maxlength="150">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <input type="email" class="form-control" placeholder="Mail Adres"
                                                    v-model="Mail" maxlength="100">
                                            </div>
                                            <div class="col-6">
                                                <input type="tel" id="phone" class="form-control"
                                                    placeholder="Telefon Numarası" v-model="Telefon" maxlength="20">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <input type="text" class="form-control" placeholder="Mesaj" v-model="Mesaj"
                                                    maxlength="250" />
                                            </div>
                                        </div>
                                        <div class="row text-right">
                                            <div class="col-6"></div>
                                            <div class="col-6">
                                                <button type="button" class="btn btn-outline-warning"
                                                    :onclick="Gonder">Gönder</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="about about-1">
                    <div class="mt-5">
                        <div class="container">
                            <div class="row text-center">
                                <h3>Güneş Enerjisine Geçişin 7 Adımı</h3>
                            </div>
                            <div class="row text-center">
                                <p>
                                    Güneş enerjisine geçmenin karmaşık olabileceğini biliyoruz. Yolun
                                    her adımında uzman rehberiniz olarak buradayız. Müşterilerimizle
                                    birlikte güneşin yönettiği bir gezegen yaratıyoruz.
                                </p>
                            </div>
                        </div>
                        <div class="container mt-5 mb-5 text-center">
                            <div class="row mt-5" id="tum">
                                <div class="col-md-2 col-xs-12"><img :src="require('../../assets/gt/7Adim/1.png')" />
                                    <p>Saha Keşfi</p>
                                    <p style="size:25px; font-weight:600;">1</p>
                                </div>
                                <div class="col-md-2 col-xs-12"><img :src="require('../../assets/gt/7Adim/2.png')" />
                                    <p>Sistem Tasarımı</p>
                                    <p style="size:25px; font-weight:600;">2</p>
                                </div>
                                <div class="col-md-2 col-xs-12"><img :src="require('../../assets/gt/7Adim/3.png')" />
                                    <p>İzin Süreçleri</p>
                                    <p style="size:25px; font-weight:600;">3</p>
                                </div>
                                <div class="col-md-2 col-xs-12"><img :src="require('../../assets/gt/7Adim/4.png')" />
                                    <p>Kurulum</p>
                                    <p style="size:25px; font-weight:600;">4</p>
                                </div>
                                <div class="col-md-2 col-xs-12"><img :src="require('../../assets/gt/7Adim/5.png')" />
                                    <p>Denetim Süreçleri</p>
                                    <p style="size:25px; font-weight:600;">5</p>
                                </div>
                                <div class="col-md-2 col-xs-12"><img :src="require('../../assets/gt/7Adim/6.png')" />
                                    <p>Şebeke Bağlantısı</p>
                                    <p style="size:25px; font-weight:600;">6</p>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-2  col-xs-12"></div>
                                <div class="col-md-2  col-xs-12"></div>
                                <div class="col-md-1  col-xs-12"></div>
                                <div class="col-md-2  col-xs-12"><img :src="require('../../assets/gt/7Adim/7.png')" />
                                    <p>Her Şey Tamam</p>
                                    <p style="size:25px; font-weight:600;">7</p>
                                </div>
                            </div>
                        </div>
                        <div class="container mt-5 mb-5 text-center">
                            <div class="row mt-5" id="Adim_1 ">
                                <div class="col-md-6">
                                    <div>
                                        <h5>1. Saha Keşfi</h5>
                                    </div>
                                    <div>
                                        <p>
                                            Uzman bir Eksper Enerji Mühendisi, başlangıçta konuştuğumuz tarihe göre planları
                                            ölçmek, gözden geçirmek ve sonuçlandırmak için ziyaret edecek.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 img-fluid">
                                    <img :src="require('../../assets/gt/7Adim/1-site-survey.png')" />
                                </div>
                            </div>
                            <div class="row mt-5" id="Adim_2">
                                <div class="col-md-6">
                                    <div>
                                        <h5>2. Sistem Tasarımı</h5>
                                    </div>
                                    <div>
                                        <p>
                                            Başlangıçta planladığımız gibi herhangi bir ince ayarı veya değişikliği gözden
                                            geçirmek ve sonuçtan memnun olduğunuzdan emin olmak için iletişim halin de
                                            oluyoruz.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 img-fluid">
                                    <img :src="require('../../assets/gt/7Adim/2-design-review.png')" />
                                </div>
                            </div>
                            <div class="row mt-5" id="Adim_3">
                                <div class="col-md-6">
                                    <div>
                                        <h5>3. İzin Süreçleri</h5>
                                    </div>
                                    <div>
                                        <p>
                                            İzin ve evrak işlerini gerekli kurumlar ile biz tamamlayacağız. Her şey yolunda
                                            gittiğinde sizlere bilgi vereceğiz.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 img-fluid">
                                    <img :src="require('../../assets/gt/7Adim/3-permitting.png')" />
                                </div>
                            </div>
                            <div class="row mt-5" id="Adim_4">
                                <div class="col-md-6">
                                    <div>
                                        <h5>4. Kurulum</h5>
                                    </div>
                                    <div>
                                        <p>
                                            Çatınız yenileniyor! Ortalama kurulum yaklaşık bir gün sürecektir.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 img-fluid">
                                    <img :src="require('../../assets/gt/7Adim/4-installation.png')" />
                                </div>
                            </div>
                            <div class="row mt-5" id="Adim_5">
                                <div class="col-md-6">
                                    <div>
                                        <h5>5. Denetim Süreçleri</h5>
                                    </div>
                                    <div>
                                        <p>
                                            Sisteminiz kurulduğunda, incelemeyi belediyeyle birlikte planlayacağız. Yeni
                                            sistemin kurallara uygun olduğundan emin olmak için belediyeden yetkili bir
                                            teknisyen uğrayacaktır.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 img-fluid">
                                    <img :src="require('../../assets/gt/7Adim/5-city-inspections.png')" />
                                </div>
                            </div>
                            <div class="row mt-5" id="Adim_6">
                                <div class="col-md-6">
                                    <div>
                                        <h5>6. Şebeke Bağlantısı</h5>
                                    </div>
                                    <div>
                                        <p>Her şeyi birbirine bağlamak için hizmet sağlayıcınızla birlikte çalışacağız.
                                            Yardımcı program sisteminizi şebekeye bağlayacağız.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 img-fluid">
                                    <img :src="require('../../assets/gt/7Adim/6-utility-connection.png')" />
                                </div>
                            </div>
                            <div class="row mt-5" id="Adim_7">
                                <div class="col-md-6">
                                    <div>
                                        <h5>7. Her Şey Tamam</h5>
                                    </div>
                                    <div>
                                        <p>
                                            Düğmeyi çevirin, arkanıza yaslanın ve güneşin sizin için çalışmasını sağlayın!
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-6 img-fluid">
                                    <img :src="require('../../assets/gt/7Adim/7-power-on.png')" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="about about-1" style="background-color: rgb(204, 204, 204,1); " id="about-1">
                    <div class="container">
                        <div class="row align-items-center vh-75">
                            <div class="col-5 text-center ">
                                <p>
                                <h3 style=" font-family:var(--global--font-heading); ">Güneş Yolculuğunuza Bugün Başlayın
                                </h3>
                                </p>
                                <router-link to="/tr/FiyatTeklif" type="button" class="btn btn-dark">Teklifinizi
                                    Alın</router-link>
                            </div>
                            <div class="col-7 ">
                                <img :src="require('../../assets/gt/start_journey.jpeg')" class="img-fluid">
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </div>
    </div>
</template>

<script>
import vendor from '../../assets/js/vendor'
import Navbar from "./SubPages-TR/navbar.vue";
import Footer from "./SubPages-TR/Footer.vue";
import GlobalServis from "../../services/GlobalServis";
export default {
    data() {
        return {
            Ad: "",
            Soyad: "",
            Mail: "",
            Telefon: "",
            Mesaj: ""
        };
    },
    methods: {
        Gonder() {
            let data = {
                Ad: this.Ad,
                Soyad: this.Soyad,
                Mail: this.Mail,
                Telefon: this.Telefon,
                Mesaj: this.Mesaj
            };
            GlobalServis.GlobalServis("POST", "SetFiyatTeklif", data).then((res) => {
                if (res.status == 200) {
                    this.ResetForm();
                    this.$toast.add({
                        severity: "success",
                        summary: "Success",
                        detail: "Teklifiniz alınmıştır.",
                        life: 3000,
                    });
                } else if (res.status == 400) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Hata",
                        detail: "Önemli bir hata meydana geldi",
                        life: 3000,
                    });
                } else {
                    this.$toast.add({
                        severity: "error",
                        summary: "Hata",
                        detail: "Bilinmeyen bir hata meydana geldi",
                        life: 3000,
                    });
                }
            });
        },
        ResetForm() {
            (this.Ad = ""), (this.Soyad = ""), (this.Mail = ""), (this.Telefon = "");
        },
    },
    components: {
        Navbar,
        Footer
    },
    mounted() {
        (function ($) {
            "use strict";
            var $bgSection = $(".bg-section");
            var $bgPattern = $(".bg-pattern");
            var $colBg = $(".col-bg");
            $bgSection.each(function () {
                var bgSrc = $(this).children("img").attr("src");
                var bgUrl = 'url(' + bgSrc + ')';
                $(this).parent().css("backgroundImage", bgUrl);
                $(this).parent().addClass("bg-section");
                $(this).remove();
            });
            $bgPattern.each(function () {
                var bgSrc = $(this).children("img").attr("src");
                var bgUrl = 'url(' + bgSrc + ')';
                $(this).parent().css("backgroundImage", bgUrl);
                $(this).parent().addClass("bg-pattern");
                $(this).remove();
            });
            $colBg.each(function () {
                var bgSrc = $(this).children("img").attr("src");
                var bgUrl = 'url(' + bgSrc + ')';
                $(this).parent().css("backgroundImage", bgUrl);
                $(this).parent().addClass("col-bg");
                $(this).remove();
            });
            var $moduleSearch = $(".module-icon-search"),
                $searchWarp = $(".module-search-warp");
            $moduleSearch.on("click", function () {
                $(this).parent().addClass("module-active");
                $(this).parent().siblings().removeClass("module-active");
                $searchWarp.addClass("search-warp-active");
            });
            var $moduleCart = $(".module-icon-cart"),
                $cartWarp = $(".module-cart-warp");
            $moduleCart.on("click", function () {
                $(this).parent().toggleClass("module-active");
                $(this).parent().siblings().removeClass("module-active");
            });
            var $module = $(".module"),
                $moduleWarp = $(".module-warp"),
                $moduleCancel = $(".module-cancel");
            $moduleCancel.on("click", function (e) {
                $module.removeClass("module-active");
                $searchWarp.removeClass("search-warp-active");
                e.stopPropagation();
                e.preventDefault();
            });
            $(document).keyup(function (e) {
                if (e.key === "Escape") {
                    $module.removeClass("module-active");
                    $moduleWarp.removeClass("active");
                    $searchWarp.removeClass("search-warp-active");
                    // $popMenuWarp.removeClass("popup-menu-warp-active");
                }
            });
            var $w = $(window);
            var $wWidth = $w.width();
            var mobile_resolution_size = "1200";
            var $dropToggle = $("[data-toggle='dropdown']");
            $dropToggle.on("click", function (event) {
                $(this).each(() => {
                    if ($wWidth <= mobile_resolution_size && $(this).attr('href') === '#') {
                        event.preventDefault();
                        event.stopPropagation();
                        $(this).parent().siblings().removeClass("show");
                        $(this).parent().toggleClass("show");
                    } else if ($wWidth <= mobile_resolution_size && !$(this).attr('href') !== '#') {
                        event.preventDefault();
                        event.stopPropagation();
                        $(this).parent().siblings().removeClass("show");
                        $(this).parent().toggleClass("show");
                        $(this).children('span').on('click', () => {
                            window.location.href = $(this).attr('href');
                        })
                    }
                })
            });
            $(window).scroll(function () {
                if ($(document).scrollTop() > 100) {
                    $('.navbar-sticky').addClass('navbar-fixed');
                } else {
                    $('.navbar-sticky').removeClass('navbar-fixed');
                }
            });
            $(".counting").counterUp({
                delay: 10,
                time: 1000
            });
            // $('.mailchimp').ajaxChimp({
            //     url: "http://wplly.us5.list-manage.com/subscribe/post?u=91b69df995c1c90e1de2f6497&id=aa0f2ab5fa",
            //     callback: chimpCallback
            // });

            function chimpCallback(resp) {
                if (resp.result === 'success') {
                    $('.subscribe-alert').html('<div class="alert alert-success">' + resp.msg + '</div>').fadeIn(1000);
                } else if (resp.result === 'error') {
                    $('.subscribe-alert').html('<div class="alert alert-danger">' + resp.msg + '</div>').fadeIn(1000);
                }
            }
            $('#campaignmonitor').submit(function (e) {
                e.preventDefault();
                $.getJSON(this.action + "?callback=?", $(this).serialize(), function (data) {
                    if (data.Status === 400) {
                        alert("Error: " + data.Message);
                    } else {
                        alert("Success: " + data.Message);
                    }
                });
            });
            var $carouselDirection = $("html").attr("dir");
            var $carouselrtl = null;
            if ($carouselDirection == "rtl") {
                $carouselrtl = true;
            } else {
                $carouselrtl = false;
            }
            $(".carousel").each(function () {
                var $Carousel = $(this);
                $Carousel.owlCarousel({
                    loop: $Carousel.data('loop'),
                    autoplay: $Carousel.data("autoplay"),
                    margin: $Carousel.data('space'),
                    nav: $Carousel.data('nav'),
                    dots: $Carousel.data('dots'),
                    dotsSpeed: $Carousel.data('speed'),
                    mouseDrag: $Carousel.data('drag'),
                    touchDrag: $Carousel.data('drag'),
                    pullDrag: $Carousel.data('drag'),
                    rtl: $carouselrtl,
                    responsive: {
                        0: {
                            items: 1,
                        },
                        768: {
                            items: $Carousel.data('slide-rs'),
                        },
                        1000: {
                            items: $Carousel.data('slide'),
                            center: $Carousel.data('center'),
                        }
                    }
                });
            });
            $(".slider-carousel").each(function () {
                var $Carousel = $(this);
                $Carousel.owlCarousel({
                    loop: $Carousel.data('loop'),
                    autoplay: $Carousel.data("autoplay"),
                    margin: $Carousel.data('space'),
                    nav: $Carousel.data('nav'),
                    dots: $Carousel.data('dots'),
                    center: $Carousel.data('center'),
                    dotsSpeed: $Carousel.data('speed'),
                    rtl: $carouselrtl,
                    responsive: {
                        0: {
                            items: 1,
                        },
                        768: {
                            items: $Carousel.data('slide-rs'),
                        },
                        1000: {
                            items: $Carousel.data('slide'),
                        }
                    },
                    animateOut: 'fadeOut',
                });
            });
            $('.testimonial-thumbs .testimonial-thumb').on('click', function () {
                $(this).siblings(".testimonial-thumb").removeClass('active');
                $(this).addClass('active');
                $(".testimonials-carousel").trigger('to.owl.carousel', [$(this).index(), 300]);
            });
            $(".testimonials-carousel").on('changed.owl.carousel', function (event) {
                var items = event.item.count;
                var item = event.item.index;
                var owlDots = document.querySelectorAll('.testimonial-thumbs .testimonial-thumb');
                if (owlDots.length > 0) {
                    owlDots[item].click()
                }
            })
            $(".process-content-carousel").on('changed.owl.carousel', function (event) {
                var items = event.item.count;
                var item = event.item.index;
                $(".process-image-carousel").trigger('to.owl.carousel', [item, 800]);
            })
            $('.entry-processes .images-holder .process-image-carousel').on('changed.owl.carousel', function (event) {
                var items = event.item.count;
                var item = event.item.index;
                $(".entry-processes .entry-body .process-content-carousel").trigger('to.owl.carousel', [item, 800]);
            })
            var $imgPopup = $(".img-popup");
            $imgPopup.magnificPopup({
                type: "image"
            });
            $('.img-gallery-item').magnificPopup({
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
            $('.popup-video,.popup-gmaps').magnificPopup({
                disableOn: 700,
                mainClass: 'mfp-fade',
                removalDelay: 0,
                preloader: false,
                fixedContentPos: false,
                type: 'iframe',
                iframe: {
                    markup: '<div class="mfp-iframe-scaler">' +
                        '<div class="mfp-close"></div>' +
                        '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                        '</div>',
                    patterns: {
                        youtube: {
                            index: 'youtube.com/',
                            id: 'v=',
                            src: '//www.youtube.com/embed/%id%?autoplay=1'
                        }
                    },
                    srcAction: 'iframe_src',
                }
            });
            var backTop = $('#back-to-top');
            if (backTop.length) {
                var scrollTrigger = 600,
                    backToTop = function () {
                        var scrollTop = $(window).scrollTop();
                        if (scrollTop > scrollTrigger) {
                            backTop.addClass('show');
                        } else {
                            backTop.removeClass('show');
                        }
                    };
                backToTop();
                $(window).on('scroll', function () {
                    backToTop();
                });
                backTop.on('click', function (e) {
                    e.preventDefault();
                    $('html,body').animate({
                        scrollTop: 0
                    }, 700);
                });
            }
            var $projectFilter = $(".projects-filter"),
                projectLength = $projectFilter.length,
                protfolioFinder = $projectFilter.find("a"),
                $projectAll = $("#projects-all");
            protfolioFinder.on("click", function (e) {
                e.preventDefault();
                $projectFilter.find("a.active-filter").removeClass("active-filter");
                $(this).addClass("active-filter");
            });
            if (projectLength > 0) {
                $projectAll.imagesLoaded().progress(function () {
                    $projectAll.isotope({
                        filter: "*",
                        animationOptions: {
                            duration: 750,
                            itemSelector: ".project-item",
                            easing: "linear",
                            queue: false,
                        }
                    });
                });
            }
            protfolioFinder.on("click", function (e) {
                e.preventDefault();
                var $selector = $(this).attr("data-filter");
                $projectAll.imagesLoaded().progress(function () {
                    $projectAll.isotope({
                        filter: $selector,
                        animationOptions: {
                            duration: 750,
                            itemSelector: ".project-item",
                            easing: "linear",
                            queue: false,
                        }
                    });
                    return false;
                });
            });
            var aScroll = $('a[data-scroll="scrollTo"]');
            aScroll.on('click', function (event) {
                var target = $($(this).attr('href'));
                if (target.length) {
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000);
                    if ($(this).hasClass("menu-item")) {
                        $(this).parent().addClass("active");
                        $(this).parent().siblings().removeClass("active");
                    }
                }
            });
            $(".progressbar").each(function () {
                $(this).waypoint(function () {
                    var progressBar = $(".progress-bar"),
                        progressBarTitle = $(".progress-title .value");
                    progressBar.each(function () {
                        $(this).css("width", $(this).attr("aria-valuenow") + "%");
                    });
                    progressBarTitle.each(function () {
                        $(this).css('opacity', 1);
                    });
                }, {
                    triggerOnce: true,
                    offset: 'bottom-in-view'
                });
            });
            var $sliderRange = $("#slider-range"),
                $sliderAmount = $("#amount");
            $sliderRange.slider({
                range: true,
                min: 0,
                max: 500,
                values: [50, 300],
                slide: function (event, ui) {
                    $sliderAmount.val("$" + ui.values[0] + " - $" + ui.values[1]);
                }
            });
            $sliderAmount.val("$" + $sliderRange.slider("values", 0) + " - $" + $sliderRange.slider("values", 1));
            var contactForm = $(".contactForm"),
                contactResult = $('.contact-result');
            contactForm.validate({
                debug: false,
                submitHandler: function (contactForm) {
                    $(contactResult, contactForm).html('Please Wait...');
                    $.ajax({
                        type: "POST",
                        url: "assets/php/contact.php",
                        data: $(contactForm).serialize(),
                        timeout: 20000,
                        success: function (msg) {
                            $(contactResult, contactForm).html('<div class="alert alert-success" role="alert"><strong>Thank you. We will contact you shortly.</strong></div>').delay(3000).fadeOut(2000);
                        },
                        error: $('.thanks').show()
                    });
                    return false;
                }
            });
            siteFooter();
            $(window).resize(function () {
                siteFooter();
            });

            function siteFooter() {
                var siteContent = $('#wrapperParallax');
                var siteFooter = $('#footerParallax');
                var siteFooterHeight = siteFooter.height();
                siteContent.css({
                    "margin-bottom": siteFooterHeight
                });
            };
            $('select').niceSelect();
            $('.collapse').on('shown.bs.collapse', function () {
                $(this).parent('.card').addClass('active-acc');
            });
            $('.collapse').on('hidden.bs.collapse', function () {
                $(this).parent('.card').removeClass('active-acc');
            });
            $("#loadMore").on("click", function (e) {
                e.preventDefault();
                $(".content.d-none").slice(0, 3).removeClass('d-none');
                if ($(".content.d-none").length == 0) {
                    $("#loadMore").addClass("d-none");
                }
            })
            // var wow = new WOW({
            //     boxClass: 'wow',
            //     animateClass: 'animated',
            //     offset: 50,
            //     mobile: false,
            //     live: true
            // });
            // wow.init();
            var imagePointer = $('.img-hotspot .img-hotspot-pointer');
            var pointerInfo = $('.img-hotspot .img-hotspot-pointer .info');
            // imagePointer.each(function(index) {
            //     $(this).css('top', $(this).data('spot-y'));
            //     $(this).css('left', $(this).data('spot-x'));
            // });
            pointerInfo.each(function (index) {
                $(this).css('top', $(this).data('info-y'));
                $(this).css('left', $(this).data('info-x'));
            });
            $('.product-quantity span ').on('click', 'a.plus, a.minus', function () {
                var qty = $(this).parents('.product-quantity').find('.pro-qunt');
                var val = parseFloat(qty.val());
                var max = parseFloat(qty.data('max'));
                var min = parseFloat(qty.data('min'));
                var step = parseFloat(qty.data('step'));
                if (isNaN(val)) {
                    val = 0;
                }
                if ($(this).is('.plus')) {
                    if (max && (max <= val)) {
                        qty.val(max);
                    } else {
                        qty.val(val + step);
                    }
                } else {
                    if (min && (min >= val)) {
                        qty.val(min);
                    } else if (val > 1) {
                        qty.val(val - step);
                    }
                }
            });
        }(window.jQuery));
    },
    beforeUnmount() { },
};
</script>

<style scoped>@import "../../assets/css/vendor.min.css";
@import "../../assets/css/style.css";
@import "../../assets/css/app.css";</style>
